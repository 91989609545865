import React from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import * as S from "./styles/__about-us.styles"
import theme from "../utils/theme"
import {
  Layout,
  FormatRichText,
  NavigationCard,
  CTACard,
  Banner,
  HeadingCard,
} from "../components"

const AboutUs = (data) => {
  const aboutUs = data.data.contentfulWebsiteDetails
  const pageDetails = data.data.contentfulWebsiteDetails.aboutUsPage

  return (
    <Layout>
      <ThemeProvider theme={{ ...theme }}>
        <S.Grid>
          <HeadingCard
            heading={pageDetails?.heading}
            description={pageDetails?.description.description}
          />
          <S.HeaderText>
            <FormatRichText text={aboutUs.aboutUsBody?.json} />
          </S.HeaderText>
          <S.Border />
          <S.Border />
          {aboutUs.partners?.map((partner, i) => (
            <NavigationCard
              key={i}
              heading={partner.type}
              image={partner.logo}
              link={partner.url}
              desc={partner?.description?.description}
            />
          ))}
          {aboutUs.aboutUsCta && (
            <>
              <CTACard
                heading={aboutUs.aboutUsCta.heading}
                title={aboutUs.aboutUsCta.title}
                desc={aboutUs.aboutUsCta.shortDescription.shortDescription}
                buttonText={aboutUs.aboutUsCta.buttonText}
                link={aboutUs.aboutUsCta.url}
              />
              <Banner image={aboutUs.homePageTextureImage} />
            </>
          )}
          <S.PartnerLogosContainer>
            {aboutUs.partners?.map((partner, i) => (
              <S.PartnerLogosWrapper key={i}>
                <S.PartnerLogo
                  src={partner?.logo?.resize?.src}
                  alt={partner?.logo?.description}
                />
              </S.PartnerLogosWrapper>
            ))}
          </S.PartnerLogosContainer>
        </S.Grid>
      </ThemeProvider>
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query {
    contentfulWebsiteDetails {
      aboutUsPage {
        heading
        description {
          description
        }
      }
      aboutUsBody {
        json
      }
      partners {
        url
        type
        logo {
          description
          resize(height: 52, quality: 100) {
            src
          }
        }
        description {
          description
        }
      }
      homePageTextureImage {
        fluid(maxWidth: 1312, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      aboutUsCta {
        heading
        title
        buttonText
        url
        shortDescription {
          shortDescription
        }
      }
    }
  }
`
