import styled from "styled-components"
import media from "styled-media-query"
import {
  HeadingCard,
  FormatRichText,
  CTACard,
  Banner,
  NavigationCard,
} from "../../components"

export const Border = styled.b`
  height: 4px;
  border-bottom: ${(props) => props.theme.borderStyle};
  grid-row: span 1;
  ${media.greaterThan("medium")`
    grid-column: span 4;
  `}
`
export const Date = styled.p``

export const HeaderText = styled.div`
  text-align: center;
  ${media.greaterThan("medium")`
    grid-column: span 4;
  `}
  background: #fff;

  p,
  ul {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const PartnerLogo = styled.img`
  max-height: 40px;
  max-width: 181px;
`
export const PartnerLogosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2.5rem 0rem;
  flex-grow: 1;
`
export const PartnerLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Grid = styled.div`
  display: grid;
  ${HeadingCard}, ${FormatRichText}, ${NavigationCard}, ${Banner} {
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${NavigationCard} {
    a {
      > * {
        max-width: 100%;
      }
      padding: 2rem 1rem;
      img {
        width: auto;
        height: 52px;
        object-fit: contain;
      }
    }
  }
  ${FormatRichText} {
    background: ${(props) => props.theme.ui03};
    padding: 2.5rem 1rem;
  }
  ${HeadingCard} {
    padding: 2.5rem 1rem;
    background: ${(props) => props.theme.ui01};
  }
  ${CTACard} {
    div:first-of-type {
      padding: 2.5rem 1rem;
    }
  }
  ${Banner} {
    min-height: 320px;
    height: 100%;
  }
  ${PartnerLogosWrapper} {
    width: 100%;
    border-bottom: ${(props) => props.theme.borderStyle};
  }
  ${media.greaterThan("414px")`
    ${HeadingCard} {
      > * {
        max-width: 70%;
      }
    }
    ${CTACard} {
        h2, p {
          max-width: 53%;
        }
      }
      ${NavigationCard} {
        a {
          > * {
            max-width: 75%;
          }
        }
      }
      ${PartnerLogosWrapper} {
        width: 50%;
        &:not(:last-child):not(:nth-child(even)) {
          border-right: ${(props) => props.theme.borderStyle};
        }
      }
  `}
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    ${HeadingCard} {
      padding-top: 4rem;
      grid-column: span 4;
      padding-bottom: 5rem;
      > * {
        max-width: 38%;
      }
    }
    ${NavigationCard} {
      min-height: 750px;
      a {
        padding: 1.5rem 2rem 2rem 2rem;
      }
    }
    ${FormatRichText} {
      grid-column: span 4;
      padding: 2rem 0rem 4rem 0rem;
      > * {
        width: 480px;
      }
    }
    ${NavigationCard} {
      &:not(:nth-child(even)) {
        border-right: ${(props) => props.theme.borderStyle};
      }
      grid-column: span 2;
    }
    ${Banner} {
      grid-column: 1 / span 4;
      grid-row: 7 / span 1;
    }
    ${CTACard} {
      grid-row: 7 / span 1;
      grid-column: 2/ 4;
      z-index: 1;
      div:first-of-type {
        padding: 4.5rem 0rem;
      }
    }
    ${PartnerLogosContainer} {
      grid-column: span 4;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    ${PartnerLogosWrapper} {
      width: 25%;
      padding-left: 1rem;
      padding-right: 1rem;
      border-right: ${(props) => props.theme.borderStyle};
      &:nth-child(even) {
        border-right: ${(props) => props.theme.borderStyle};
      }
    }
  `}
  ${media.between("medium", "1055px")`
    ${CTACard} {
      h2, p {
        max-width: 70%;
      }
    }
  `}
`
